import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import Axios from 'axios'
import VueCookies from 'vue-cookies'
import moment from "moment";
import md5 from "js-md5";

Vue.use(VueCookies);
Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

let baseURL;
// baseURL = process.env.NODE_ENV === "production" ? 'https://user.airmy.life/api' : 'http://airmylife.htroy.com/api';
Axios.defaults.baseURL = '/api';
Axios.defaults.withCredentials = true;

Axios.defaults.validateStatus = function validateStatus(status) {
  return status >= 200;
};

Vue.prototype.$axios = Axios;
Vue.prototype.$moment = moment;
Vue.prototype.$md5 = md5;

router.beforeEach((to, from, next) => {
  if (Vue.prototype.$cookies.get('key') === null && (to.fullPath !== '/auth/login' && to.fullPath !== '/auth/register')) {
    next('/auth/login');
  } if (Vue.prototype.$cookies.get('key') !== null && (to.fullPath === '/auth/login' || to.fullPath === '/auth/register')) {
    console.log(Vue.prototype.$cookies.get('key'))
    next('/index');
  } else {
    next();
  }
})

Axios.interceptors.response.use(function (response) {
  if (response.status === 401) {
    router.push(response.data.newLocation);
  }
  if (response.status === 404) {
    router.push('/404');
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});

Vue.filter('dateFormat', function (daraStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  if (daraStr < 1000000000000) daraStr *= 1000
  return moment(daraStr).format(pattern)
})

Vue.filter('dateFormatDate', function (daraStr, pattern = 'YYYY-MM-DD') {
  if (daraStr < 1000000000000) daraStr *= 1000
  return moment(daraStr).format(pattern)
})

Vue.filter('percent', function (daraStr) {
  return daraStr.toFixed(2)+"%"
})

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
