import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// User
const Index = () => import('@/views/Index')
const Node = () => import('@/views/user/Node')
const TrafficLog = () => import('@/views/user/TrafficLog')
const Relay = () => import('@/views/user/Relay')

// User Account
const Info = () => import('@/views/user/Info')
const BoughtHistory = () => import('@/views/user/BoughtHistory')

// Auth
const Login = () => import('@/views/auth/Login')
const Logout = () => import('@/views/auth/Logout')
const Register = () => import('@/views/auth/Register')
const PageNotFound = () => import('@/views/404')

//Admin
const AdminUserList = () => import('@/views/admin/user/List')
const AdminUserEdit = () => import('@/views/admin/user/Edit')


Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/index',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'index',
          name: 'Index',
          component: Index
        },
        {
          path: 'node',
          name: 'Node',
          component: Node
        },
        {
          path: 'traffic-log',
          name: 'Traffic Log',
          component: TrafficLog
        },
        {
          path: 'relay',
          name: 'Relay Rule',
          component: Relay
        },
        {
          path: 'account',
          redirect: '/account/info',
          name: 'Account',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'info',
              name: 'Info',
              component: Info
            },
            {
              path: 'bought',
              name: 'Bought History',
              component: BoughtHistory
            }
          ]
        },
      ]
    },
    {
      path: '/admin',
      redirect: '/admin/user/list',
      name: 'Admin',
      component: TheContainer,
      children: [
        {
          path: 'user',
          redirect: '/admin/user/list',
          name: 'User',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'list',
              name: 'List',
              component: AdminUserList
            },
            {
              path: 'edit/:id',
              name: 'Edit',
              component: AdminUserEdit
            }
          ]
        },
      ]
    },
    {
      path: '/auth',
      redirect: '/index',
      name: 'Auth',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'logout',
          name: 'Logout',
          component: Logout
        }
      ]
    },
    {
      path: '/404',
      name: 'Page Not Found',
      component: PageNotFound
    },
    {
      path: '*',
      name: 'Page Not Found',
      component: PageNotFound
    }
  ]
}

